import arrow from '../assets/arrow.svg'

export default function Blog() {
    return (
        <div>
            <main>
                Blog Will Go Here.
            </main>
        </div>
    )
}